<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Product List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:27px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										style="margin-top:27px; margin-left:5px;"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
								</template>
								<template slot="slot-active" slot-scope="props">
									<CBadge :color="props.rowData.active ? 'success' : 'danger'">
										{{ props.rowData.active ? "YES" : "NO" }}
									</CBadge>
								</template>
								<template slot="slot-winner" slot-scope="props">
									<CLink
										@click="getParticipant(props.rowData.id)"
										href="#"
										class="font-weight-bold"
									>
										{{
											props.rowData.winner ? props.rowData.winner.name : "SET"
										}}
									</CLink>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modalSet"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Set Winner"
			color="info"
		>
			<div class="position-relative table-responsive">
				<div class="table-wrapper-scroll-y my-custom-scrollbar">
					<table class="table table-sm table-bordered table-striped mb-0">
						<tr>
							<th>Name</th>
							<th></th>
						</tr>
						<tr v-for="(par, i) in participants" :key="par.id">
							<td>{{ par.user.name }}</td>
							<td>
								<CButton
									@click="selectPartisipant(i)"
									size="sm"
									color="primary"
								>
									SELECT
								</CButton>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<h6>
				Winner : <span class="text-info">{{ selectedPartisipant.name }}</span>
			</h6>
			<CRow>
				<CCol md="12">
					<CInput
						label="Note"
						v-model="frmSetParticipant.winNote"
						placeholder="note"
					/>
				</CCol>
			</CRow>
			<template #footer>
				<CButton @click="modalSet = false" color="danger">CANCEL</CButton>
				<CButton @click="saveWin" :disabled="loadingSaveSet" color="success">
					SAVE
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";

export default {
	data() {
		return {
			uri: basuri + "product",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			modalSet: false,

			fields: [
				{
					name: "name",
					title: "Name",
					sortField: "name",
				},
				{
					name: "category.name",
					title: "Category",
					sortField: "category.name",
				},
				{
					name: "price",
					title: "Price",
					sortField: "price",
					formatter(value) {
						return value ? value.toLocaleString() : 0;
					},
					dataClass: "text-right",
				},
				{
					name: "winWay",
					title: "Win Way",
					sortField: "winWay",
					dataClass: "text-center",
				},
				{
					name: "slot-winner",
					title: "Winner",
					sortField: "winner",
					dataClass: "text-center",
				},
				{
					name: "expired",
					title: "Exp.",
					sortField: "expired",
					formatter(value) {
						return new Date(value * 1000).toISOString().split("T")[0];
					},
				},
				{
					name: "slot-active",
					title: "Active",
					sortField: "active",
				},
				{
					name: "add",
					title: "Add by",
					sortField: "add.name",
					formatter(value) {
						return value ? value.name : "-";
					},
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
			participants: [],
			frmSetParticipant: {
				winner: 0,
				winNote: "",
			},
			loadingParticipant: false,
			selectedPartisipant: {},
			loadingSaveSet: false,
		};
	},
	watch: {
		"moreParams.filter": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.$router.push({ name: "Product Form", params: { id: data.id } });
			} else {
				this.$router.push({ name: "Product Form" });
			}
		},

		saveWin: debounce(async function() {
			this.loadingSaveSet = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.post(
						"campaign/" + this.selectedId + "/win",
						this.frmSetParticipant
					);
					datar = data;

					if (datar.status == "success") {
						this.$toast.success("Success ! " + datar.message);
						this.$refs.vuetable.refresh();
						this.modalSet = false;
					} else {
						this.$swal("Fail !", datar.message, "error");
					}
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loadingSaveSet = false;
		}, 500),

		async getParticipant(productId) {
			this.loadingParticipant = true;
			this.participants = [];
			this.selectedId = productId;

			this.modalSet = true;
			this.frmSetParticipant = {
				winWay: "pengajuan",
				winner: 0,
				winNote: "",
			};
			this.selectedPartisipant = {
				name: "",
				id: 0,
			};

			const { data } = await this.$http.get(
				"campaign/" + productId + "/participant"
			);
			if (data.status == "success") {
				this.participants = data.data;
			}
			this.loadingParticipant = false;
		},
		selectPartisipant(i) {
			this.selectedPartisipant = {
				name: this.participants[i].user.name,
				id: this.participants[i].user.id,
			};

			this.frmSetParticipant.winner = this.participants[i].user.id;
		},
	},
};
</script>

<style lang="css">
.my-custom-scrollbar {
	position: relative;
	height: 200px;
	overflow: auto;
}
.table-wrapper-scroll-y {
	display: block;
}
</style>
